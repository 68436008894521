import React, { useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import NotificationAlert from 'react-notification-alert';

import {
  axiosGet,
  applyFiltersForPausedAcc,
  findHoneymoonNotes,
} from '../utils';
import axiosRetry from 'axios-retry';
import axios from 'axios';
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Col,
  Row,
  Button,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

import Select from 'react-select';
import SyndAccountCard from 'components/Syndicates/SyndAccountCard';

import { bookieListGenerator } from 'components/dropdownGenerator';
import { syndicateListGenerator } from 'components/dropdownGenerator';

const bookieArr = bookieListGenerator('syndAccounts');
const pausedBookieArray = bookieListGenerator('analytics');
const syndArr = syndicateListGenerator('syndAccounts');

axiosRetry(axios, { retries: 3 });

const bookieSelectOptions = (arr) => {
  const defaultOption = [
    {
      value: '',
      label: 'Select bookies...',
      isDisabled: true,
    },
  ];

  const currentArray = !arr ? bookieArr : arr;

  const opts = currentArray
    .sort((a, b) => a.localeCompare(b))
    .map((bookie) => {
      return { value: bookie, label: bookie };
    });

  if (!arr) {
    return defaultOption.concat(opts);
  }
  return opts;
};

const syndSelectOptions = () => {
  const defaultOption = [
    {
      value: '',
      label: 'Select bookies...',
      isDisabled: true,
    },
  ];
  const opts = syndArr
    .sort((a, b) => a.localeCompare(b))
    .map((syndicate) => {
      return { value: syndicate, label: syndicate };
    });

  return defaultOption.concat(opts);
};

const options = bookieSelectOptions();
const syndOptions = syndSelectOptions();

const pausedBookieFilterInit = bookieSelectOptions(pausedBookieArray);

const Syndicates = () => {
  // sourceData is the data that is received from syndicateMX
  // account Data is a local copy of sourceData that can be modified and used to display on page
  const [sourceData, setSourceData] = useState([]);
  const [syndicateMultipleSelect, setSyndicateMultipleSelect] = useState([]);
  const [bookieMultipleSelect, setBookieMultipleSelect] = useState([]);
  const [pausedBookieMultipleSelect, setPausedBookieMultipleSelect] = useState(
    pausedBookieFilterInit
  );

  const [syndFilterMultipleSelect, setSyndFilterMultipleSelect] = useState([]);
  const [bookFilterMultipleSelect, setBookFilterMultipleSelect] = useState([]);
  const [loading, setLoading] = useState(false);
  const [horizontalTabs, sethorizontalTabs] = useState('syndicateAccounts');
  const [betTypeMultipleSelect, setbetTypeMulipleSelect] = useState([
    { value: 'lucky31', label: 'Lucky31' },
    { value: 'lucky15', label: 'Lucky15' },
    { value: 'patent', label: 'Patent' },
    { value: 'trixie', label: 'Trixie' },
    { value: 'double', label: 'Double' },
    { value: 'single', label: 'Single' },
  ]);
  const [balanceFilter, setBalanceFilter] = useState('');
  const [activityFilter, setActivityFilter] = useState('');
  const [enabledFilter, setEnabledFilter] = useState('');
  const [notesSearchTerm, setNotesSearchTerm] = useState('');
  const [pauseSearchTerm, setPauseSearchTerm] = useState('');
  const [pausedFilter, setPausedFilter] = useState('paused');
  const [alert, setAlert] = useState(null);

  const notificationAlertRef = React.useRef(null);

  //// SYNDICATE MODAL STUFF

  const hideAlert = () => {
    setAlert(null);
  };

  const basicAlert = (message) => {
    setAlert(
      <ReactBSAlert
        style={{ display: 'block', marginTop: '-100px' }}
        title={`${message}`}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const notify = (place, status, msg) => {
    let colour;
    switch (status) {
      case 200:
        colour = 'success';
        break;
      case 404:
        colour = 'danger';
        break;
      case 504:
        colour = 'danger';
    }

    var options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>{msg}</div>
        </div>
      ),
      type: colour,
      icon: 'tim-icons icon-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  //// END SYNDICATE MODAL STUFF

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case 'horizontalTabs':
        sethorizontalTabs(tabName);
        break;
      case 'verticalTabsIcons':
        setverticalTabsIcons(tabName);
        break;
      case 'pageTabs':
        setpageTabs(tabName);
        break;
      case 'verticalTabs':
        setverticalTabs(tabName);
        break;
      default:
        break;
    }
  };

  function moreFilters(input) {
    let filteredInput = input;

    if (horizontalTabs === 'listLiveAccounts') {
      filteredInput = [input];
    }

    if (enabledFilter) {
      filteredInput = filteredInput.map((item) => {
        const filteredItem = item.filter((account) => {
          return account.enabled === enabledFilter;
        });
        return filteredItem;
      });
    } else if (enabledFilter === false) {
      filteredInput = filteredInput.map((item) => {
        const filteredItem = item.filter((account) => {
          return account.enabled === enabledFilter;
        });
        return filteredItem;
      });
    }
    if (activityFilter) {
      filteredInput = filteredInput.map((item) => {
        const filteredItem = item.filter((account) => {
          return account.type === activityFilter;
        });
        return filteredItem;
      });
    }
    if (balanceFilter) {
      if (balanceFilter === 'low') {
        filteredInput = filteredInput.map((item) => {
          const filteredItem = item.filter((account) => {
            return account.balance < 100;
          });
          return filteredItem;
        });
      }
      if (balanceFilter === 'high') {
        filteredInput = filteredInput.map((item) => {
          const filteredItem = item.filter((account) => {
            return account.balance > 500;
          });
          return filteredItem;
        });
      }
    }
    if (notesSearchTerm && notesSearchTerm.length > 0) {
      filteredInput = filteredInput.map((item) => {
        const filteredItem = item.filter((account) => {
          if (account.notes && account.notes.length > 0) {
            const matchedNote = account.notes.filter((note) => {
              return note.toLowerCase().includes(notesSearchTerm.toLowerCase());
            });

            return matchedNote.length > 0;
          }
        });
        return filteredItem;
      });
    }
    if (pauseSearchTerm && pauseSearchTerm.length > 0) {
      filteredInput = filteredInput.map((item) => {
        const filteredItem = item.filter((account) => {
          if (account.pauseReason) {
            return account.pauseReason
              .toLowerCase()
              .includes(pauseSearchTerm.toLowerCase());
          }
        });
        return filteredItem;
      });
    }

    filteredInput = filteredInput.map((item) => {
      const betTypeFilters = betTypeMultipleSelect.map((betType) => {
        return betType.value;
      });
      let betTypeFilteredAccounts;

      betTypeFilteredAccounts = item.filter((account) => {
        const accountBetTypes = account.betTypes.map((bet) => {
          return bet.betTypeName;
        });
        return accountBetTypes.some(
          (item) => betTypeFilters.indexOf(item) >= 0
        );
      });

      return betTypeFilteredAccounts;
    });

    return filteredInput;
  }

  function bookieDataFilters(inputData) {
    let appliedFilter;
    let currentSynds;
    let filteredBySynd;
    appliedFilter = inputData;

    if (syndFilterMultipleSelect.length === 0) {
      currentSynds = [
        ...new Set(
          inputData.map((d) => {
            return d.name;
          })
        ),
      ].sort();
    }
    if (syndFilterMultipleSelect.length > 0) {
      currentSynds = syndFilterMultipleSelect.map((syndicate) =>
        syndicate.value.toLowerCase().replace(' ', '#')
      );
    }

    appliedFilter = bookieMultipleSelect.map((bookie) => {
      const bookieGroup = inputData.filter(
        (item) => item.bookie === bookie.value.toLowerCase().replace(' ', '#')
      );
      return bookieGroup;
    });

    filteredBySynd = appliedFilter.map((group) => {
      const filtered = currentSynds.map((synd) => {
        const filteredItem = group.filter((g) => {
          return synd === g.name;
        });
        return filteredItem;
      });
      return filtered.flatMap((item) => item);
    });

    const finalData = moreFilters(filteredBySynd);
    const groupCards = finalData.map((group) => {
      if (group.length > 0) {
        return (
          <Card>
            <CardBody className="account-card-main">
              <h2 className="account-heading">{`(${group.length})`} -{' '}{group[0].bookie}</h2>
              <hr />
              {group.map((account, index) => {
                return (
                  <SyndAccountCard
                    cardNum={index}
                    data={account}
                    updateSourceData={updateSourceData}
                  />
                );
              })}
            </CardBody>
          </Card>
        );
      }
    });
    return groupCards;
  }

  function syndicateDataFilters(inputData) {
    let appliedFilter;
    let currentBookies;
    let filteredByBookie;
    appliedFilter = inputData;

    if (bookFilterMultipleSelect.length === 0) {
      currentBookies = [
        ...new Set(
          inputData.map((d) => {
            return d.bookie;
          })
        ),
      ].sort();
    }
    if (bookFilterMultipleSelect.length > 0) {
      currentBookies = bookFilterMultipleSelect.map((bookie) => bookie.value);
    }

    appliedFilter = syndicateMultipleSelect.map((synd) => {
      const syndGroup = inputData.filter(
        (item) => item.name === synd.value.toLowerCase().replace(' ', '#')
      );
      return syndGroup;
    });

    filteredByBookie = appliedFilter.map((group) => {
      const filtered = currentBookies.map((bookie) => {
        const filteredItem = group.filter((g) => {
          return bookie === g.bookie;
        });
        return filteredItem;
      });
      return filtered.flatMap((item) => item);
    });

    const finalData = moreFilters(filteredByBookie);

    const groupCards = finalData.map((group) => {
      if (group.length > 0) {
        return (
          <Card>
            <CardBody className="account-card-main">
              <h2 className="account-heading">
              {`(${group.length})`} - {group[0].name.replace('#', ' ')} -{' '}
                
                {group[0].proxy !== undefined
                  ? group[0].proxy.replace('socks5://', '')
                  : 'No Proxy Assigned'}
              </h2>
              {group.map((account, index) => {
                return (
                  <SyndAccountCard
                    cardNum={index}
                    data={account}
                    updateSourceData={updateSourceData}
                  />
                );
              })}
            </CardBody>
          </Card>
        );
      }
    });
    return groupCards;
  }

  function getAllFilters(inputData) {
    let bookieFilteredData;

    if (pausedBookieMultipleSelect.length > 0) {
      const currentBookies = pausedBookieMultipleSelect.map(
        (bookie) => bookie.value
      );

      bookieFilteredData = inputData.filter(
        (data) =>
          currentBookies.includes(data.bookie) &&
          !findHoneymoonNotes(data.notes, 'honeymoon')
      );
    }
    if (pausedBookieMultipleSelect.length === 0) {
      bookieFilteredData = inputData;
    }
    const finalData = moreFilters(bookieFilteredData);

    const cards = finalData[0].map((account, index) => {
      if (inputData.length > 0) {
        return (
          <SyndAccountCard
            cardNum={index}
            key={`${account.name}-${account.bookie}`}
            data={account}
            updateSourceData={updateSourceData}
          />
        );
      }
    });

    // return cards;

    return (
      <Card>
        <CardBody className="account-card-main">
          {cards && cards.length > 0 ? (
            <h2 className="account-heading"> {`(${cards.length})`} -{' '} Paused Accounts</h2>
          ) : (
            ''
          )}
          {cards}
        </CardBody>
      </Card>
    );
  }

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : null;

  const updateSourceData = (newItem) => {
    // find original object
    const originalAccountItem = sourceData.find(
      (item) =>
        item.bookie === newItem.query.bookie && item.name === newItem.name
    );
    const updatedAccountItem = { ...originalAccountItem, ...newItem };

    setSourceData((prevData) =>
      prevData.map((obj) =>
        obj.name === updatedAccountItem.name &&
        obj.bookie === updatedAccountItem.bookie
          ? updatedAccountItem
          : obj
      )
    );
  };

  const handleSubmitClick = async (type) => {
    setLoading(true);
    setSourceData([]);
    let url;
    let array;

    if (type === 'syndicate') {
      array = await Promise.all(
        syndicateMultipleSelect.map(async (item) => {
          url = `https://api.valueservices.uk/syndicateMX/getAccountsByName/${item.value
            .replace(' ', '%23')
            .toLowerCase()}`;
          const response = await axiosGet(url);
          if (response.name === 'AxiosError') {
            basicAlert(response.message);
            return [];
          }
          return response;
        })
      );
    }
    if (type === 'bookie') {
      array = await Promise.all(
        bookieMultipleSelect.map(async (item) => {
          url = `https://api.valueservices.uk/syndicateMX/getSyndicateAccounts/${item.value}/*/*`;
          const response = await axiosGet(url);
          if (response.name === 'AxiosError') {
            basicAlert(response.message);
            return [];
          }
          return response;
        })
      );
    }
    if (type === 'all') {
      array = await Promise.all(
        syndArr.map(async (item) => {
          url = `https://api.valueservices.uk/syndicateMX/getAccountsByName/${item
            .replace(' ', '%23')
            .toLowerCase()}`;
          const response = await axiosGet(url);
          if (response.name === 'AxiosError') {
            basicAlert(response.message);
            return [];
          }
          return response;
        })
      );
    }

    let flatData = array.flatMap((arr) => arr);

    if (type === 'all') {
      flatData = applyFiltersForPausedAcc(pausedFilter, flatData);
    }

    setSourceData(flatData);
    setLoading(false);
  };

  const GetAllSubmitButton =
    sourceData.length > 0 ? (
      <Button onClick={() => handleSubmitClick('all')} color="primary">
        Update {loadingSpinner}
      </Button>
    ) : (
      <Button onClick={() => handleSubmitClick('all')} color="primary">
        Submit {loadingSpinner}
      </Button>
    );

  const SyndicateSubmitButton =
    sourceData.length > 0 ? (
      <Button onClick={() => handleSubmitClick('syndicate')} color="primary">
        Update {loadingSpinner}
      </Button>
    ) : syndicateMultipleSelect.length > 0 ? (
      <Button onClick={() => handleSubmitClick('syndicate')} color="primary">
        Submit {loadingSpinner}
      </Button>
    ) : (
      <Button disabled color="primary">
        Submit
      </Button>
    );

  const BookieSubmitButton =
    sourceData.length > 0 ? (
      <Button onClick={() => handleSubmitClick('bookie')} color="primary">
        Update {loadingSpinner}
      </Button>
    ) : bookieMultipleSelect.length > 0 ? (
      <Button onClick={() => handleSubmitClick('bookie')} color="primary">
        Submit {loadingSpinner}
      </Button>
    ) : (
      <Button disabled color="primary">
        Submit
      </Button>
    );

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlertRef} />
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h3">View Available Accounts</CardTitle>
              </CardHeader>
              <CardBody>
                <Nav className="nav-pills-info" pills>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'syndicateAccounts' ? 'active' : ''
                      }
                      onClick={(e) => {
                        changeActiveTab(
                          e,
                          'horizontalTabs',
                          'syndicateAccounts'
                        );
                        setSourceData([]);
                        setBookieMultipleSelect([]);
                        setSyndFilterMultipleSelect([]);
                      }}
                    >
                      Search By Syndicate
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'bookieAccounts' ? 'active' : ''
                      }
                      onClick={(e) => {
                        changeActiveTab(e, 'horizontalTabs', 'bookieAccounts');
                        setSourceData([]);
                        setSyndicateMultipleSelect([]);
                        setBookFilterMultipleSelect([]);
                      }}
                    >
                      Search By Bookie
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      data-toggle="tab"
                      href="#pablo"
                      className={
                        horizontalTabs === 'listLiveAccounts' ? 'active' : ''
                      }
                      onClick={(e) => {
                        changeActiveTab(
                          e,
                          'horizontalTabs',
                          'listLiveAccounts'
                        );
                        setSourceData([]);
                        setBookieMultipleSelect([]);
                        setSyndFilterMultipleSelect([]);
                      }}
                    >
                      Paused Accounts
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="tab-space" activeTab={horizontalTabs}>
                  <TabPane tabId="syndicateAccounts">
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <CardTitle tag="h3">
                              Select Syndicates To Query
                            </CardTitle>
                            <Row>
                              <Col>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Syndicate Name..."
                                  name="syndicateMultipleSelect"
                                  closeMenuOnSelect={true}
                                  isMulti
                                  value={syndicateMultipleSelect}
                                  onChange={(value) =>
                                    setSyndicateMultipleSelect(value)
                                  }
                                  options={syndOptions}
                                />
                              </Col>
                              <Col>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Filter By Bookie..."
                                  name="bookieMultipleSelect"
                                  closeMenuOnSelect={true}
                                  isMulti
                                  value={bookFilterMultipleSelect}
                                  onChange={(value) =>
                                    setBookFilterMultipleSelect(value)
                                  }
                                  options={[{ options }]}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>{SyndicateSubmitButton}</Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="bookieAccounts">
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            <CardTitle tag="h3">
                              Select Bookies to Query
                            </CardTitle>
                            <Row>
                              <Col>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Bookie..."
                                  name="bookieMultipleSelect"
                                  closeMenuOnSelect={true}
                                  isMulti
                                  value={bookieMultipleSelect}
                                  onChange={(value) =>
                                    setBookieMultipleSelect(value)
                                  }
                                  options={[{ options }]}
                                />
                              </Col>
                              <Col>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Filter By Syndicate Name..."
                                  name="syndicateMultipleSelect"
                                  closeMenuOnSelect={true}
                                  isMulti
                                  value={syndFilterMultipleSelect}
                                  onChange={(value) =>
                                    setSyndFilterMultipleSelect(value)
                                  }
                                  options={syndOptions}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>{BookieSubmitButton}</Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="listLiveAccounts">
                    <Row>
                      <Col md="12">
                        <Card>
                          <CardBody>
                            <CardTitle tag="h3">
                              Select Bookies to Query for Paused Accounts
                            </CardTitle>
                            <Row>
                              <Col>
                                <Select
                                  className="react-select info"
                                  classNamePrefix="react-select"
                                  placeholder="Bookie..."
                                  name="pausedBookieMultipleSelect"
                                  closeMenuOnSelect={true}
                                  isMulti
                                  value={pausedBookieMultipleSelect}
                                  onChange={(value) =>
                                    setPausedBookieMultipleSelect(value)
                                  }
                                  options={[{ options }]}
                                />
                              </Col>
                            </Row>
                            <hr />
                            <Row>
                              <Col>
                                <FormGroup
                                  check
                                  className="form-check-radio form-check-inline"
                                >
                                  <Label className="form-check-label">
                                    <Input
                                      type="radio"
                                      name="pausedAccountRadios"
                                      id="filterRadio1"
                                      value=""
                                      defaultChecked
                                      onClick={() => {
                                        setPausedFilter('paused');
                                      }}
                                    />
                                    Paused Accounts
                                    <span className="form-check-sign"></span>
                                  </Label>
                                </FormGroup>
                                <FormGroup
                                  check
                                  className="form-check-radio form-check-inline"
                                >
                                  <Label className="form-check-label">
                                    <Input
                                      type="radio"
                                      name="pausedAccountRadios"
                                      id="filterRadio2"
                                      value=""
                                      onClick={() => {
                                        setPausedFilter('high');
                                      }}
                                    />
                                    High Balances
                                    <span className="form-check-sign"></span>
                                  </Label>
                                </FormGroup>
                                <FormGroup
                                  check
                                  className="form-check-radio form-check-inline"
                                >
                                  <Label className="form-check-label">
                                    <Input
                                      type="radio"
                                      name="pausedAccountRadios"
                                      id="filterRadio3"
                                      value=""
                                      onClick={() => {
                                        setPausedFilter('low');
                                      }}
                                    />
                                    Low Balances
                                    <span className="form-check-sign"></span>
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>

                            <hr />
                            {pausedFilter === 'paused' ? (
                              <CardTitle tag="h3">
                                List Disabled Live Accounts{' '}
                              </CardTitle>
                            ) : pausedFilter === 'high' ? (
                              <CardTitle tag="h3">
                                Retrieve High Balance Accounts
                              </CardTitle>
                            ) : (
                              <CardTitle tag="h3">
                                Retrieve Low Balance Accounts
                              </CardTitle>
                            )}

                            {GetAllSubmitButton}
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
                {horizontalTabs !== 'listLiveAccounts' ? (
                  <>
                    <hr />
                    <CardTitle tag="h4">Filters</CardTitle>
                    <Row>
                      <Col md="4">
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="enabledRadios"
                              id="enabledRadio1"
                              value=""
                              defaultChecked
                              onClick={() => {
                                setPausedFilter('');
                              }}
                            />
                            No Enabled Filter
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="enabledRadios"
                              id="enabledRadio2"
                              value=""
                              onClick={() => {
                                setEnabledFilter(true);
                              }}
                            />
                            Placing<span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="enabledRadios"
                              id="enabledRadio3"
                              value=""
                              onClick={() => {
                                setEnabledFilter(false);
                              }}
                            />
                            Paused<span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="activeStatus"
                              id="activeStatus1"
                              value="NoBalanceFilter"
                              defaultChecked
                              onClick={() => {
                                setActivityFilter('');
                              }}
                            />
                            No Activity Filter
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="activeStatus"
                              id="activeStatus2"
                              value="active"
                              onClick={() => {
                                setActivityFilter('Active');
                              }}
                            />
                            Active<span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="activeStatus"
                              id="activeStatus3"
                              value="inactive"
                              onClick={() => {
                                setActivityFilter('Inactive');
                              }}
                            />
                            Inactive<span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="balanceRadios"
                              id="balanceRadio1"
                              value="NoBalanceFilter"
                              defaultChecked
                              onClick={() => {
                                setBalanceFilter('');
                              }}
                            />
                            No Balance Filter
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="balanceRadios"
                              id="balanceRadio2"
                              value="lowBalance"
                              onClick={() => {
                                setBalanceFilter('low');
                              }}
                            />
                            Low Balance<span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                        <FormGroup
                          check
                          className="form-check-radio form-check-inline"
                        >
                          <Label className="form-check-label">
                            <Input
                              type="radio"
                              name="balanceRadios"
                              id="balanceRadio3"
                              value="highBalance"
                              onClick={() => {
                                setBalanceFilter('high');
                              }}
                            />
                            High Balance
                            <span className="form-check-sign"></span>
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                <hr />
                <Row>
                  <Col>
                    <CardTitle tag="h4">
                      Search by Notes and Pause Reason
                    </CardTitle>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Input
                            placeholder="Search By Account Notes..."
                            name="SyndicateName"
                            type="text"
                            onChange={(e) => {
                              setNotesSearchTerm(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Input
                            placeholder="Search By Pause Reason..."
                            name="SyndicateName"
                            type="text"
                            onChange={(e) => {
                              setPauseSearchTerm(e.target.value);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    <CardTitle tag="h4">Filter By Bet Type</CardTitle>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      placeholder="Filter By Bookie..."
                      name="bookieMultipleSelect"
                      closeMenuOnSelect={true}
                      isMulti
                      value={betTypeMultipleSelect}
                      onChange={(value) => setbetTypeMulipleSelect(value)}
                      options={[
                        {
                          value: '',
                          label: 'Select Bet Type',
                          isDisabled: true,
                        },
                        { value: 'lucky31', label: 'Lucky31' },
                        { value: 'lucky15', label: 'Lucky15' },
                        { value: 'patent', label: 'Patent' },
                        { value: 'trixie', label: 'Trixie' },
                        { value: 'double', label: 'Double' },
                        { value: 'single', label: 'Single' },
                      ]}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            {horizontalTabs === 'syndicateAccounts'
              ? syndicateDataFilters(sourceData)
              : horizontalTabs === 'bookieAccounts'
              ? bookieDataFilters(sourceData)
              : getAllFilters(sourceData)}
          </Col>
        </Row>
        {alert}
      </div>
    </>
  );
};
export default Syndicates;
