import { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Button,
  Spinner,
  Card,
  CardTitle,
  CardBody,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Table,
  TabContent,
  TabPane,
} from 'reactstrap';
import { CSVLink } from 'react-csv';

import { axiosGet } from '../utils';
import { bookieListGenerator } from 'components/dropdownGenerator';
const bookies = bookieListGenerator('accountsToOpen');

const headers = [
  { label: 'Partner', key: 'displayName' },
  { label: 'Bookie', key: 'bookie' },
  { label: 'Pause Reason', key: 'pauseReason' },
  { label: 'Latest Note', key: 'latestNote' },
  { label: 'Balance', key: 'balance' },
  { label: 'Last Placed', key: 'timeStamp'}
];

const Turnover = () => {
  const [loading, setLoading] = useState(false);
  const [horizontalTabs, sethorizontalTabs] = useState();

  const [syndicateAccounts, setSyndicateAccounts] = useState([]);
  const [activeAccounts, setActiveAccounts] = useState([]);
  const [pausedAccounts, setPausedAccounts] = useState([]);
  const [pausedDueToBalance, setPausedDueToBalance] = useState([]);
  const [pausedDueToRestriction, setPausedDueToRestriction] = useState([]);
  const [pausedDueToHoneymoon, setPausedDueToHoneymoon] = useState([]);
  const [pausedDueToSuspension, setPausedDueToSuspension] = useState([]);
  const [pausedDueToFunding, setPausedDueToFunding] = useState([]);
  const [pausedDueToDeposit, setPausedDueToDeposit] = useState([]);
  const [predictedTurnover, setPredictedTurnover] = useState(0);
  const [predictedTurnoverBalance, setPredictedTurnoverBalance] = useState(0);
  const [turnoverList, setTurnoverList] = useState([]);
  const [todaysBets, setTodaysBets] = useState([]);
  const [bets, setBets] = useState([]);

  const formatTimestamp = (timestamp) => {
    const formattedTime = moment(timestamp).format('HH:mm - DD/MM/YYYY');
    const relativeTime = moment(timestamp).fromNow();
    return `${formattedTime} (${relativeTime})`;
  };

  const changeActiveTab = (e, tabState, tabName) => {
    e.preventDefault();
    switch (tabState) {
      case 'horizontalTabs':
        horizontalTabs === tabName
          ? sethorizontalTabs()
          : sethorizontalTabs(tabName);
        break;
      default:
        break;
    }
  };

  const getAverageStakeForBookie = async (bookie) => {
    const res = await axiosGet(
      `https://api.valueservices.uk/reporting/getSyndStakeReports/${bookie}/${moment()
        .subtract(1, 'weeks')
        .format('YYYY-MM-DD')}/${moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`
    );

    const bets = res
      .flatMap((a) => a.totalsByBetType)
      .filter((a) => a.totalBets > 0);

    const groupedBetTypes = bets.reduce((acc, item) => {
      const group = item.betType;
      if (!acc[group]) acc[group] = [];
      acc[group].push(item);
      return acc;
    }, {});

    const averages = Object.keys(groupedBetTypes).map((a) => {
      const totalStake = groupedBetTypes[a].reduce(
        (partialSum, a) => partialSum + a.totalStake,
        0
      );
      const totalBets = groupedBetTypes[a].reduce(
        (partialSum, a) => partialSum + a.totalBets,
        0
      );

      const averageStake = (totalStake / totalBets).toFixed(2);

      return { bookie, betType: a, averageStake };
    });

    return averages;
  };

  const loadTodayBetData = async () => {
    setLoading(true);

    const url2 = `https://api.valueservices.uk/horsebettingmx/getBets/${moment().format(
      'YYYY-MM-DD'
    )}`;
    const response2 = await axiosGet(url2);
    let saturdayBets2 = response2;
    if (response2.name === 'AxiosError') {
      console.log(response2);
      saturdayBets2 = [];
    }
    setTodaysBets(saturdayBets2.filter((b) => b.status === 'placed'));

    turnoverList.map((t) => {
      t.numberOfBets = saturdayBets2.filter(
        (d) =>
          d.displayName === t.name &&
          d.bookie === t.bookie &&
          d.status === 'placed'
      ).length;
    });

    setTurnoverList(turnoverList);

    setLoading(false);
  };

  const getBets = async () => {
    let saturdayBets, day;
    let resultsProcessTime = moment('13:00', 'HH:mm');

    if (moment().isBefore(resultsProcessTime)) {
      day = moment().subtract(1, 'days').format('YYYY-MM-DD');
    } else {
      day = moment().format('YYYY-MM-DD');
    }

    const url = `https://api.valueservices.uk/horsebettingmx/getBets/${day}`;
    const response = await axiosGet(url);
    saturdayBets = response;
    if (response.name === 'AxiosError') {
      console.log(response);
      saturdayBets = [];
    }

    setBets(saturdayBets.filter((b) => b.status === 'placed'));
    return saturdayBets.filter((b) => b.status === 'placed');
  };

  const getBookieAccounts = async () => {
    setLoading(true);
    const accounts = await Promise.all(
      bookies.map(async (item) => {
        const url = `https://api.valueservices.uk/syndicateMX/getSyndicateAccounts/${item.name}/*/*`;

        const response = await axiosGet(url);
        if (response.name === 'AxiosError') {
          console.log(response);
          return [];
        }
        return response;
      })
    );

    const averages = (
      await Promise.all(
        bookies.map(async (item) => {
          const average = await getAverageStakeForBookie(item.name);
          return average;
        })
      )
    ).flatMap((a) => a);

    let allActiveAccounts = accounts
      .flatMap((a) => a)
      .filter((a) => a.type === 'Active')
      .sort((a, b) => a.name.localeCompare(b.name))
    
    allActiveAccounts = allActiveAccounts.map((i) => {
      const latestNote = i.notes.length > 0 ? i.notes[i.notes.length - 1] : ""
      const timeStamp = i.lastBet ? formatTimestamp(i.lastBet) : 'Never Placed'
      return {...i, latestNote, timeStamp}
    })

    setSyndicateAccounts(allActiveAccounts);

    const allPausedAccounts = allActiveAccounts.filter(
      (a) => a.enabled === false
    );
    setPausedAccounts(allPausedAccounts);

    setPausedDueToBalance(
      allPausedAccounts.filter((a) =>
        a.pauseReason.toLowerCase().includes('balance')
      )
    );

    setPausedDueToRestriction(
      allPausedAccounts.filter(
        (a) =>
          a.pauseReason.toLowerCase().includes('restrict') ||
          a.pauseReason.toLowerCase().includes('maximum') ||
          a.pauseReason.toLowerCase().includes('stake')
      )
    );

    setPausedDueToHoneymoon(
      allPausedAccounts.filter((a) =>
        a.notes.join().toLowerCase().includes('honey')
      )
    );
    setPausedDueToSuspension(
      allPausedAccounts.filter((a) =>
        a.notes.join().toLowerCase().includes('susp')
      )
    );
    setPausedDueToFunding(
      allPausedAccounts.filter((a) =>
        a.notes.join().toLowerCase().includes('fund')
      )
    );
    setPausedDueToDeposit(
      allPausedAccounts.filter((a) =>
        a.notes.join().toLowerCase().includes('deposit')
      )
    );

    const placingAccounts = allActiveAccounts.filter(
      (aa) => aa.enabled === true
    );

    const turnoverArray = placingAccounts.map((aa) => {
      const foundAverage = averages.find(
        (a) =>
          a.bookie === aa.bookie && a.betType === aa.betTypes[0].betTypeName
      );

      let averageStake;
      if (!foundAverage) {
        averageStake =
          aa.betTypes[0].betTypeName === 'lucky15'
            ? aa.betTypes[0].stake * 15
            : aa.betTypes[0].betTypeName === 'patent'
            ? aa.betTypes[0].stake * 7
            : aa.betTypes[0].betTypeName === 'trixie'
            ? aa.betTypes[0].stake * 4
            : aa.betTypes[0].stake;
      } else {
        averageStake = foundAverage.averageStake;
      }

      return {
        name: aa.displayName,
        bookie: aa.bookie,
        stake: averageStake * aa.maxBets,
        balance: aa.balance,
        stakeBalance:
          averageStake * aa.maxBets > aa.balance
            ? aa.balance
            : averageStake * aa.maxBets,
        maxBets: aa.maxBets,
      };
    });

    //const saturdayBets = await getBets();

    // For every account, loop through and get an average of their stake from the last busiest day.. if not single, then use programmed stake.. if single, get average of another syndicate
    // const placingAccounts = allActiveAccounts.filter(
    //   (aa) => aa.enabled === true
    // );
    // console.log(bookies);
    // const turnoverArray = placingAccounts.map((aa) => {
    //   const myBets = saturdayBets.filter(
    //     (sb) => sb.name === aa.name && sb.bookie === aa.bookie
    //   );
    //   if (myBets && myBets.length > 0) {
    //     const averageStake =
    //       myBets.reduce((partialSum, a) => partialSum + a.stake, 0) /
    //       myBets.length;

    //     const betTypeStake =
    //       aa.betTypes[0].betTypeName === 'lucky15'
    //         ? aa.betTypes[0].stake * 15
    //         : aa.betTypes[0].betTypeName === 'patent'
    //         ? aa.betTypes[0].stake * 7
    //         : aa.betTypes[0].betTypeName === 'trixie'
    //         ? aa.betTypes[0].stake * 4
    //         : aa.betTypes[0].stake;

    //     if (averageStake * aa.maxBets > betTypeStake * aa.maxBets) {
    //       return {
    //         name: aa.displayName,
    //         bookie: aa.bookie,
    //         stake: betTypeStake * aa.maxBets,
    //       };
    //     } else {
    //       return {
    //         name: aa.displayName,
    //         bookie: aa.bookie,
    //         stake: averageStake * aa.maxBets,
    //       };
    //     }
    //   } else {
    //     if (aa.betTypes[0].betTypeName === 'lucky15') {
    //       return {
    //         name: aa.displayName,
    //         bookie: aa.bookie,
    //         stake: aa.betTypes[0].stake * 15 * aa.maxBets,
    //       };
    //     } else if (aa.betTypes[0].betTypeName === 'patent') {
    //       return {
    //         name: aa.displayName,
    //         bookie: aa.bookie,
    //         stake: aa.betTypes[0].stake * 7 * aa.maxBets,
    //       };
    //     } else if (aa.betTypes[0].betTypeName === 'trixie') {
    //       return {
    //         name: aa.displayName,
    //         bookie: aa.bookie,
    //         stake: aa.betTypes[0].stake * 4 * aa.maxBets,
    //       };
    //     } else if (aa.betTypes[0].betTypeName === 'double') {
    //       return {
    //         name: aa.displayName,
    //         bookie: aa.bookie,
    //         stake: aa.betTypes[0].stake * aa.maxBets,
    //       };
    //     } else {
    //       const otherBets = saturdayBets.filter(
    //         (sb) =>
    //           sb.bookie === aa.bookie &&
    //           sb.betType === aa.betTypes[0].betTypeName
    //       );
    //       if (otherBets && otherBets.length > 0) {
    //         const averageStake =
    //           myBets.reduce((partialSum, a) => partialSum + a.stake, 0) /
    //           myBets.length;
    //         return {
    //           name: aa.displayName,
    //           bookie: aa.bookie,
    //           stake: averageStake * aa.maxBets,
    //         };
    //       } else {
    //         return {
    //           name: aa.displayName,
    //           bookie: aa.bookie,
    //           stake: aa.betTypes[0].stake * aa.maxBets,
    //         };
    //       }
    //     }
    //   }
    // });

    // console.log(allActiveAccounts);
    // const lucky15Accounts = allActiveAccounts.filter(
    //   (aa) => aa.betTypes[0].betTypeName === 'lucky15'
    // );

    // const patentAccounts = allActiveAccounts.filter(
    //   (aa) => aa.betTypes[0].betTypeName === 'patent'
    // );

    setPredictedTurnover(
      turnoverArray
        .reduce((partialSum, a) => partialSum + a.stake, 0)
        .toFixed(2)
    );

    setPredictedTurnoverBalance(
      turnoverArray
        .reduce((partialSum, a) => partialSum + a.stakeBalance, 0)
        .toFixed(2)
    );

    setTurnoverList(turnoverArray.sort((a, b) => b.stake - a.stake));

    setLoading(false);
  };

  const loadBalancesWithWinnings = async () => {
    setLoading(true);

    const daysBets = await getBets();

    turnoverList.map((t) => {
      const filterBets = daysBets.filter(
        (d) => d.displayName === t.name && d.bookie === t.bookie
      );
      t.balanceWithWinnings =
        t.balance +
        filterBets.reduce((partialSum, a) => partialSum + a.winnings, 0);
      t.numberOfBets = todaysBets.filter(
        (d) => d.displayName === t.name && d.bookie === t.bookie
      ).length;
    });

    setLoading(false);
  };

  const loadingSpinner = loading ? (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  ) : (
    'Load'
  );

  const AccountTabs = () => {
    if (!horizontalTabs) {
      return <></>;
    }
    return (
      <>
        <Table striped bordered>
          <thead>
            <th>Name</th>
            <th>Bookie</th>
            <th>Latest Note</th>
            <th>Last Placed</th>
          </thead>
          <tbody>
            {horizontalTabs === 'pausedAccounts' ? (
              pausedAccounts.map((p) => (
                <tr>
                  <td>{p.displayName}</td>
                  <td>{p.bookie}</td>
                  <td>
                    {p.notes && p.notes.length > 0
                      ? p.notes[p.notes.length - 1]
                      : ''}
                  </td>
                  <td>
                    {p.lastBet ? formatTimestamp(p.lastBet) : 'Never Placed'}
                  </td>
                </tr>
              ))
            ) : horizontalTabs === 'pausedBalance' ? (
              pausedDueToBalance.map((p) => (
                <tr>
                  <td>{p.displayName}</td>
                  <td>{p.bookie}</td>
                  <td>
                    {p.notes && p.notes.length > 0
                      ? p.notes[p.notes.length - 1]
                      : ''}
                  </td>
                  <td>
                    {p.lastBet ? formatTimestamp(p.lastBet) : 'Never Placed'}
                  </td>
                </tr>
              ))
            ) : horizontalTabs === 'pausedRestriction' ? (
              pausedDueToRestriction.map((p) => (
                <tr>
                  <td>{p.displayName}</td>
                  <td>{p.bookie}</td>
                  <td>
                    {p.notes && p.notes.length > 0
                      ? p.notes[p.notes.length - 1]
                      : ''}
                  </td>
                  <td>
                    {p.lastBet ? formatTimestamp(p.lastBet) : 'Never Placed'}
                  </td>
                </tr>
              ))
            ) : horizontalTabs === 'pausedHoneymoon' ? (
              pausedDueToHoneymoon.map((p) => (
                <tr>
                  <td>{p.displayName}</td>
                  <td>{p.bookie}</td>
                  <td>
                    {p.notes && p.notes.length > 0
                      ? p.notes[p.notes.length - 1]
                      : ''}
                  </td>
                  <td>
                    {p.lastBet ? formatTimestamp(p.lastBet) : 'Never Placed'}
                  </td>
                </tr>
              ))
            ) : horizontalTabs === 'pausedSuspension' ? (
              pausedDueToSuspension.map((p) => (
                <tr>
                  <td>{p.displayName}</td>
                  <td>{p.bookie}</td>
                  <td>
                    {p.notes && p.notes.length > 0
                      ? p.notes[p.notes.length - 1]
                      : ''}
                  </td>
                  <td>
                    {p.lastBet ? formatTimestamp(p.lastBet) : 'Never Placed'}
                  </td>
                </tr>
              ))
            ) : horizontalTabs === 'pausedFunding' ? (
              pausedDueToFunding.map((p) => (
                <tr>
                  <td>{p.displayName}</td>
                  <td>{p.bookie}</td>
                  <td>
                    {p.notes && p.notes.length > 0
                      ? p.notes[p.notes.length - 1]
                      : ''}
                  </td>
                  <td>
                    {p.lastBet ? formatTimestamp(p.lastBet) : 'Never Placed'}
                  </td>
                </tr>
              ))
            ) : horizontalTabs === 'pausedDeposit' ? (
              pausedDueToDeposit.map((p) => (
                <tr>
                  <td>{p.displayName}</td>
                  <td>{p.bookie}</td>
                  <td>
                    {p.notes && p.notes.length > 0
                      ? p.notes[p.notes.length - 1]
                      : ''}
                  </td>
                  <td>
                    {p.lastBet ? formatTimestamp(p.lastBet) : 'Never Placed'}
                  </td>
                </tr>
              ))
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <div className="content">
      <Card>
        <Button
          onClick={async () => {
            await getBookieAccounts();
          }}
        >
          {loadingSpinner}
        </Button>
        {syndicateAccounts && (
          <Table striped bordered>
            <thead>
              <tr>
                <th>Active Accounts</th>
                <th>Paused Accounts</th>
                <th>Paused due to Balance</th>
                <th>Paused due to Restriction</th>
                <th>Paused due to Honeymoon</th>
                <th>Paused due to Suspension</th>
                <th>Paused due to Funding</th>
                <th>Paused awaiting Deposit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{syndicateAccounts.length}</td>
                <td>{pausedAccounts.length}</td>
                <td>{pausedDueToBalance.length}</td>
                <td>{pausedDueToRestriction.length}</td>
                <td>{pausedDueToHoneymoon.length}</td>
                <td>{pausedDueToSuspension.length}</td>
                <td>{pausedDueToFunding.length}</td>
                <td>{pausedDueToDeposit.length}</td>
              </tr>
            </tbody>
          </Table>
        )}
        <Nav className="nav-pills-info" pills>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'pausedAccounts' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'pausedAccounts');
              }}
            >
              Paused Accounts
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'pausedBalance' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'pausedBalance');
              }}
            >
              Paused - Balance
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'pausedRestriction' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'pausedRestriction');
              }}
            >
              Paused - Restriction
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'pausedHoneymoon' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'pausedHoneymoon');
              }}
            >
              Paused - Honeymoon
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'pausedSuspension' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'pausedSuspension');
              }}
            >
              Paused - Suspension
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'pausedFunding' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'pausedFunding');
              }}
            >
              Paused - Funding
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              data-toggle="tab"
              href="#pablo"
              className={horizontalTabs === 'pausedDeposit' ? 'active' : ''}
              onClick={(e) => {
                changeActiveTab(e, 'horizontalTabs', 'pausedDeposit');
              }}
            >
              Paused - Deposit
            </NavLink>
          </NavItem>
        </Nav>

        {pausedAccounts.length > 0 ? (
          <Button color="secondary">
            <CSVLink filename="Current_paused_acc" data={pausedAccounts} headers={headers}>
              Download Account Data
            </CSVLink>
          </Button>
        ) : (
          <Button disabled>
              No Data
          </Button>
        )}

        {AccountTabs()}
      </Card>
      <Card>
        <Row>
          <Col md={6}>
            <h4 style={{ marginTop: 20, textAlign: 'center' }}>
              Turnover on perfect day: <b>£{predictedTurnover}</b>
            </h4>
          </Col>
          <Col md={6}>
            <h4 style={{ marginTop: 20, textAlign: 'center' }}>
              Maximum possible due to account balances:{' '}
              <b>£{predictedTurnoverBalance}</b>
            </h4>
          </Col>
        </Row>
        <Col md={12}>
          <Table striped bordered>
            <thead>
              <th>Name</th>
              <th>Bookie</th>
              <th>Stake</th>
              <th>Balance</th>
              <th>
                Balance - Winnings{' '}
                {turnoverList && turnoverList.length > 0 && (
                  <Button
                    className="btn-sm"
                    onClick={async () => {
                      await loadBalancesWithWinnings();
                    }}
                  >
                    {loadingSpinner}
                  </Button>
                )}
              </th>
              <th>
                Bets Placed/Max Bets Today{' '}
                {turnoverList && turnoverList.length > 0 && (
                  <Button
                    className="btn-sm"
                    onClick={async () => {
                      await loadTodayBetData();
                    }}
                  >
                    {loadingSpinner}
                  </Button>
                )}
              </th>{' '}
            </thead>
            <tbody>
              {turnoverList.map((t) => (
                <tr>
                  <td>{t.name}</td>
                  <td>{t.bookie}</td>
                  <td>{t.stake.toFixed(2)}</td>
                  <td>
                    <p
                      style={
                        t.balance < t.stake
                          ? { color: 'red', fontWeight: 800 }
                          : {}
                      }
                    >
                      {t.balance.toFixed(2)}
                    </p>
                  </td>
                  <td>
                    {t.balanceWithWinnings && (
                      <p
                        style={
                          t.balanceWithWinnings < t.stake
                            ? { color: 'red', fontWeight: 800 }
                            : {}
                        }
                      >
                        {t.balanceWithWinnings.toFixed(2)}
                      </p>
                    )}
                  </td>
                  <td>
                    {t.numberOfBets}/{t.maxBets}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Card>
    </div>
  );
};

export default Turnover;
