import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Button,
  Modal,
  ModalBody,
  Label,
  FormGroup,
  Input,
  Row,
  Col,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';

import TimeSlider from './TimeSlider';
import DumbTimeSlider from './DumbTimeSlider';

import { axiosPut, preventScroll } from 'utils';

const SyndAccountCard = ({ cardNum, data, updateSourceData }) => {
  const {
    balance,
    betTypes,
    bookie,
    days,
    displayName,
    enabled,
    endTime,
    maxBets,
    name,
    notes,
    password,
    pauseReason,
    startTime,
    type,
    username,
  } = data;

  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const date = today.getDate().toString().padStart(2, '0');
  const formattedDate = `${year}-${month}-${date}`;

  const [loading, setLoading] = useState(false);
  const [placingPausedLoading, setPlacingPausedLoading] = useState(false);
  const [activeInactiveLoading, setActiveInactiveLoading] = useState(false);
  const [draftNote, setDraftNote] = useState('');
  const [noteLoading, setNoteLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(<></>);
  const [removeNoteLoading, setRemoveNoteLoading] = useState(false);

  const spinner = (
    <>
      {' '}
      <Spinner size="sm"></Spinner>
    </>
  );

  const loadingSpinner = loading ? spinner : null;
  const placingPausedSpinner = placingPausedLoading ? spinner : null;
  const activeInactiveSpinner = activeInactiveLoading ? spinner : null;
  const noteLoadingSpinner = noteLoading ? spinner : null;
  const removeNoteLoadingSpinner = removeNoteLoading ? spinner : null;

  const sliderProps = {
    min: 21600000,
    max: 79200000,
    step: 300000,
    marks: {
      21600000: '06:00',
      25200000: '7',
      28800000: '8',
      32400000: '9',
      36000000: '10',
      39600000: '11',
      43200000: '12',
      46800000: '13',
      50400000: '14',
      54000000: '15',
      57600000: '16',
      61200000: '17',
      64800000: '18',
      68400000: '19',
      72000000: '20',
      75600000: '21',
      79200000: '22:00',
    },
  };

  const updateNewAccountDetails = (key, value) => {
    if (key === 'time') {
      setItemsToUpdate((prevDetails) => ({
        ...prevDetails,
        startTime: value[0],
        endTime: value[1],
      }));
      setIsUpdated(true);
      return;
    }
    if (key === 'notes') {
      setItemsToUpdate((prevDetails) => ({
        ...prevDetails,
        draftNote: value,
      }));
      setIsUpdated(true);
      return;
    }
    setItemsToUpdate((prevDetails) => ({
      ...prevDetails,
      [key]: value,
    }));
    setIsUpdated(true);
  };

  const [modalClassic, setModalClassic] = React.useState(false);
  const [itemsToUpdate, setItemsToUpdate] = React.useState({
    name,
    query: { bookie },
  });
  const [isUpdated, setIsUpdated] = useState(false);

  const toggleEditBox = () => {
    setModalClassic(!modalClassic);
    setItemsToUpdate({
      name,
      query: { bookie },
    });
    setErrorMessage(<></>);
  };

  const balanceColour = balance < 100 ? 'red' : 'green';

  const displayDays = () => {
    const dayStrings = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    let resultArr = [];
    for (let index = 0; index < 7; index++) {
      resultArr.push(
        days.includes(index) ? (
          <>
            <span
              style={{
                color: '#ff4d4d',
                borderRadius: '10px',
                padding: '3px',
                margin: '3px',
              }}
            >
              <strong>{dayStrings[index]}</strong>
            </span>
            {' / '}
          </>
        ) : (
          <>
            <span style={{ textDecoration: 'line-through' }}>
              {dayStrings[index]}
            </span>
            {' / '}
          </>
        )
      );
    }
    return resultArr;
  };

  const sendNote = async () => {
    const user = await Auth.currentSession();
    setErrorMessage(
      <p className="text-warning">
        <strong> Saving New Note...</strong>
      </p>
    );
    setNoteLoading(true);

    if (draftNote && draftNote.length > 0) {
      itemsToUpdate.notes = [
        ...notes,
        `${formattedDate} : ${draftNote} ~ ${user.accessToken.payload.username}`,
      ];
    }

    const responseObj = await axiosPut(itemsToUpdate);
    if (
      responseObj.data &&
      responseObj.data === 'Updated' &&
      responseObj.status &&
      responseObj.status === 200
    ) {
      updateSourceData(itemsToUpdate);
      setDraftNote('');
      setNoteLoading(false);
      setErrorMessage(
        <p className="text-success">
          <strong>Successfully Saved</strong>
        </p>
      );
      return;
    }

    if (responseObj.message) {
      setErrorMessage(
        <p className="text-danger">
          <strong>{responseObj.message}</strong>
        </p>
      );
      setNoteLoading(false);
      return;
    }

    setNoteLoading(false);
  };

  const handleToggle = (day, isChecked) => {
    const currentDays = days;
    if (isChecked && !currentDays.includes(day)) {
      currentDays.push(day);
    }
    if (!isChecked && currentDays.includes(day)) {
      const index = currentDays.indexOf(day);
      if (index > -1) {
        currentDays.splice(index, 1);
      }
    }

    updateNewAccountDetails('days', currentDays.sort());
  };

  const dayCheckBoxes = () => {
    return (
      <>
        <FormGroup check inline>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id={`checkBoxSun`}
              defaultChecked={days.includes(0)}
              onClick={(e) => {
                handleToggle(0, e.target.checked);
              }}
            />
            <span className="form-check-sign" />
            Sun
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <FormGroup check inline>
            <Label check>
              <Input
                className="form-check-input"
                type="checkbox"
                value=""
                id={`checkBoxSun`}
                defaultChecked={days.includes(1)}
                onClick={(e) => {
                  handleToggle(1, e.target.checked);
                }}
              />
              <span className="form-check-sign" />
              Mon
            </Label>
          </FormGroup>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id={`checkBoxSun`}
              defaultChecked={days.includes(2)}
              onClick={(e) => {
                handleToggle(2, e.target.checked);
              }}
            />
            <span className="form-check-sign" />
            Tue
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id={`checkBoxSun`}
              defaultChecked={days.includes(3)}
              onClick={(e) => {
                handleToggle(3, e.target.checked);
              }}
            />
            <span className="form-check-sign" />
            Wed
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id={`checkBoxSun`}
              defaultChecked={days.includes(4)}
              onClick={(e) => {
                handleToggle(4, e.target.checked);
              }}
            />
            <span className="form-check-sign" />
            Thu
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id={`checkBoxSun`}
              defaultChecked={days.includes(5)}
              onClick={(e) => {
                handleToggle(5, e.target.checked);
              }}
            />
            <span className="form-check-sign" />
            Fri
          </Label>
        </FormGroup>
        <FormGroup check inline>
          <Label check>
            <Input
              className="form-check-input"
              type="checkbox"
              value=""
              id={`checkBoxSun`}
              defaultChecked={days.includes(6)}
              onClick={(e) => {
                handleToggle(6, e.target.checked);
              }}
            />
            <span className="form-check-sign" />
            Sat
          </Label>
        </FormGroup>
      </>
    );
  };

  const pausedPlacingButton = () => {

    const button = enabled ? (
      <>
        <Button
          onClick={async () => {
            const user = await Auth.currentSession();
            setPlacingPausedLoading(true);
            const requestObj = {
              name,
              query: { bookie },
              enabled: false,
              notes: [
                ...notes,
                `*** Set PAUSED by: ${
                  user.accessToken.payload.username
                } - ${new Date().toISOString()} ***`,
              ],
            };

            const reqResponse = await axiosPut(requestObj);
            if (
              reqResponse.data &&
              reqResponse.data === 'Updated' &&
              reqResponse.status &&
              reqResponse.status === 200
            ) {
              updateSourceData(requestObj);
              setPlacingPausedLoading(false);
              return;
            }
            setPlacingPausedLoading(false);
          }}
          size="sm"
          color="success"
        >
          {placingPausedSpinner} Placing
        </Button>
      </>
    ) : (
      <>
        <Button
          onClick={async () => {
            const user = await Auth.currentSession();
            setPlacingPausedLoading(true);
            const requestObj = {
              name,
              query: { bookie },
              enabled: true,
              type: 'Active',
              pauseReason: '',
              notes: [
                ...notes,
                `*** Set PLACING by: ${
                  user.accessToken.payload.username
                } - ${new Date().toISOString()} ***`,
              ],
            };
            const reqResponse = await axiosPut(requestObj);
            if (
              reqResponse.data &&
              reqResponse.data === 'Updated' &&
              reqResponse.status &&
              reqResponse.status === 200
            ) {
              updateSourceData(requestObj);
              setPlacingPausedLoading(false);
              return;
            }
            setPlacingPausedLoading(false);
          }}
          size="sm"
          color="danger"
        >
          {placingPausedSpinner} Paused
        </Button>
      </>
    );

    return button;
  };

  const activeInactiveButton = () => {
    const button =
      type === 'Active' ? (
        <>
          <Button
            onClick={async () => {
              const user = await Auth.currentSession();
              setActiveInactiveLoading(true);
              const requestObj = {
                name,
                query: { bookie },
                type: 'Inactive',
                enabled: false,
                notes: [
                  ...notes,
                  `*** Set INACTIVE by: ${user.accessToken.payload.username} - ${new Date().toISOString()} ***`,
                ],
              };
              const reqResponse = await axiosPut(requestObj);

              if (
                reqResponse.data &&
                reqResponse.data === 'Updated' &&
                reqResponse.status &&
                reqResponse.status === 200
              ) {
                updateSourceData(requestObj);
                setActiveInactiveLoading(false);
                return;
              }
              setActiveInactiveLoading(false);
            }}
            size="sm"
            color="success"
          >
            {activeInactiveSpinner} Active
          </Button>
        </>
      ) : (
        <>
          <Button
            onClick={async () => {
              const user = await Auth.currentSession();
              setActiveInactiveLoading(true);
              const requestObj = {
                name,
                query: { bookie },
                type: 'Active',
                pauseReason: '',
                notes: [
                  ...notes,
                  `*** Set ACTIVE by: ${
                    user.accessToken.payload.username
                  } - ${new Date().toISOString()} ***`,
                ],
              };
              const reqResponse = await axiosPut(requestObj);

              if (
                reqResponse.data &&
                reqResponse.data === 'Updated' &&
                reqResponse.status &&
                reqResponse.status === 200
              ) {
                updateSourceData(requestObj);
                setActiveInactiveLoading(false);
                return;
              }
              setActiveInactiveLoading(false);
            }}
            size="sm"
            color="danger"
          >
            {activeInactiveSpinner} Inactive
          </Button>
        </>
      );

    return button;
  };

  const updateNotesButton = () => {
    const noteIcon =
      noteLoading === true ? (
        noteLoadingSpinner
      ) : (
        <i className={'tim-icons icon-send'} style={{ paddingBottom: '4px' }} />
      );
    const buttonElement =
      draftNote && draftNote.length > 0 ? (
        <Button
          onClick={async () => {
            sendNote();
          }}
          color="success"
          className="send-button"
        >
          {noteIcon}
        </Button>
      ) : (
        <Button disabled color="success" className="send-button">
          <i
            className={'tim-icons icon-send'}
            style={{ paddingBottom: '4px' }}
          />
        </Button>
      );
    return buttonElement;
  };

  const removeNote = async (noteIndex) => {
    setRemoveNoteLoading(true);
    setErrorMessage(
      <p className="text-warning">
        <strong> Deleting Note...</strong>
      </p>
    );

    const tempNotes = [...notes];
    tempNotes.splice(noteIndex, 1);

    const notesObj = { ...itemsToUpdate, notes: tempNotes };

    const responseObj = await axiosPut(notesObj);
    if (
      responseObj.data &&
      responseObj.data === 'Updated' &&
      responseObj.status &&
      responseObj.status === 200
    ) {
      updateSourceData(notesObj);
      setRemoveNoteLoading(false);
      setErrorMessage(
        <p className="text-success">
          <strong>Successfully Removed Note</strong>
        </p>
      );
      return;
    }
    if (responseObj.message) {
      setErrorMessage(
        <p className="text-danger">
          <strong>{responseObj.message}</strong>
        </p>
      );
      setRemoveNoteLoading(false);
      return;
    }

    setRemoveNoteLoading(true);
  };

  const updateAllbutton = () => {
    const element = isUpdated ? (
      <Button
        color="success"
        onClick={async () => {
          setErrorMessage(
            <p className="text-warning">
              <strong> Saving Updates...</strong>
            </p>
          );
          setLoading(true);

          const responseObj = await axiosPut(itemsToUpdate);

          if (
            responseObj.data &&
            responseObj.data === 'Updated' &&
            responseObj.status &&
            responseObj.status === 200
          ) {
            updateSourceData(itemsToUpdate);
            setIsUpdated(false);
            setLoading(false);
            setErrorMessage(
              <p className="text-success">
                <strong>Successfully Saved</strong>
              </p>
            );
            return;
          }

          if (responseObj.message) {
            setErrorMessage(
              <p className="text-danger">
                <strong>{responseObj.message}</strong>
              </p>
            );
            setLoading(false);
            return;
          }

          setErrorMessage(
            <p className="text-danger">
              <strong>
                Something unexpected Happened... Let the devs know
              </strong>
            </p>
          );
          setLoading(false);
        }}
      >
        Update Account
        {loadingSpinner}
      </Button>
    ) : (
      <Button disabled>Update Account</Button>
    );
    return element;
  };

  return (
    <>
      <div
        className={`${
          cardNum % 2 === 0 ? 'account-card-even' : 'account-card-odd'
        } account-card`}
      >
        <Row>
          <Col md="5">
            <Row>
              <Col>
                <div>
                  <h4 style={{ marginBottom: '0px' }}>
                    {bookie}
                    {' - '}
                    {displayName}
                    {' - '}
                    <strong
                      style={{ color: balanceColour }}
                    >{`£${balance} `}</strong>
                  </h4>
                  <strong> Strategy : </strong>
                  {betTypes[0].betTypeName} {`£${betTypes[0].stake}/line`} x{' '}
                  {maxBets}
                  <div>
                    <strong>Username:</strong> {username} /{' '}
                    <strong>Pass:</strong> {password}{' '}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong className={notes.length > 0 ? 'text-danger' : ''}>
                  Latest Note :{' '}
                  {[...notes].reverse().find((note) => !note.startsWith('***'))}
                </strong>
              </Col>
            </Row>
            <Row>
              <Col>
                {' '}
                {pauseReason ? (
                  <div className="text-danger">{`Pause Reason: ${pauseReason}`}</div>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <DumbTimeSlider
              account={data}
              startTime={startTime}
              endTime={endTime}
              disabled={true}
              displayDays={displayDays}
              sliderProps={sliderProps}
            />
          </Col>
          <Col className="ml-auto text-right">
            <Button
              color="primary"
              size="sm"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                toggleEditBox();
              }}
            >
              <i
                className={'tim-icons icon-pencil icon-syndicate-card'}
                style={{ paddingBottom: '4px' }}
              />{' '}
            </Button>
            {activeInactiveButton()}
            {pausedPlacingButton()}
          </Col>
        </Row>
      </div>

      <Modal isOpen={modalClassic} backdrop="static" toggle={toggleEditBox}>
        <div className="modal-header justify-content-center">
          <button
            aria-hidden={true}
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleEditBox}
          >
            <i className="tim-icons icon-simple-remove icon-syndicate-card" />
          </button>
          <h4 className="title title-up">{`Edit ${displayName}'s ${bookie} Account`}</h4>
        </div>

        <ModalBody className="text-center">
          <hr />
          <Row>
            <Col md="6">
              <div>
                <strong>Bet Type: {betTypes[0].betTypeName}</strong>
              </div>
            </Col>
            <Col md="1">
              <div>
                <i className="tim-icons icon-minimal-right icon-syndicate-card" />
              </div>
            </Col>
            <Col>
              <Select
                className="react-select info"
                classNamePrefix="react-select"
                name="Group Results"
                onChange={(value) =>
                  updateNewAccountDetails('betTypes', [
                    {
                      stake:
                        itemsToUpdate.betTypes !== undefined
                          ? itemsToUpdate.betTypes[0].stake
                          : betTypes[0].stake,
                      betTypeName: value.label,
                      order: 1,
                    },
                  ])
                }
                options={[
                  {
                    value: '',
                    label: 'Type',
                    isDisabled: true,
                  },
                  {
                    value: 'single',
                    label: 'single',
                  },
                  {
                    value: 'double',
                    label: 'double',
                  },
                  {
                    value: 'trixie',
                    label: 'trixie',
                  },
                  {
                    value: 'patent',
                    label: 'patent',
                  },
                  {
                    value: 'lucky15',
                    label: 'lucky15',
                  },
                  {
                    value: 'lucky31',
                    label: 'lucky31',
                  },
                ]}
                placeholder="Type"
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div>
                <strong>Stake: {betTypes[0].stake}</strong>
              </div>
            </Col>
            <Col md="1">
              <div>
                <i className="tim-icons icon-minimal-right" />
              </div>
            </Col>
            <Col>
              <Input
                className="modal-input"
                type="number"
                placeholder={`Stake: ${betTypes[0].stake}`}
                onFocus={preventScroll}
                onChange={(e) =>
                  updateNewAccountDetails('betTypes', [
                    {
                      stake: parseFloat(e.target.value),
                      betTypeName:
                        itemsToUpdate.betTypes !== undefined
                          ? itemsToUpdate.betTypes[0].betTypeName
                          : betTypes[0].betTypeName,
                      order: 1,
                    },
                  ])
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div>
                <strong>Max Bets: {maxBets}</strong>
              </div>
            </Col>
            <Col md="1">
              <div>
                <i className="tim-icons icon-minimal-right" />
              </div>
            </Col>
            <Col>
              <Input
                className="modal-input"
                type="number"
                onFocus={preventScroll}
                placeholder={`Max Bets: ${maxBets}`}
                onChange={(e) =>
                  updateNewAccountDetails('maxBets', parseFloat(e.target.value))
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div>
                <strong>UserName: {username}</strong>
              </div>
            </Col>
            <Col md="1">
              <div>
                <i className="tim-icons icon-minimal-right " />
              </div>
            </Col>
            <Col>
              <Input
                className="modal-input"
                placeholder={`New Username`}
                onChange={(e) =>
                  updateNewAccountDetails('username', e.target.value)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div>
                <strong>Password: {password}</strong>
              </div>
            </Col>
            <Col md="1">
              <div>
                <i className="tim-icons icon-minimal-right" />
              </div>
            </Col>
            <Col>
              <Input
                className="modal-input"
                placeholder={`New password`}
                onChange={(e) =>
                  updateNewAccountDetails('password', e.target.value)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <div>
                <strong>Update Balance: {balance}</strong>
              </div>
            </Col>
            <Col md="1">
              <div>
                <i className="tim-icons icon-minimal-right" />
              </div>
            </Col>
            <Col>
              <Input
                className="modal-input"
                type="number"
                onFocus={preventScroll}
                placeholder={`Edit Current Balance`}
                onChange={(e) =>
                  updateNewAccountDetails('balance', parseFloat(e.target.value))
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div>
                <strong>Schedule</strong>
              </div>
              {dayCheckBoxes()}

              <TimeSlider
                startTime={startTime}
                endTime={endTime}
                disabled={false}
                updateNewAccountDetails={updateNewAccountDetails}
                sliderProps={sliderProps}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <strong>Notes</strong>
              {notes.map((note, index) => {
                const noteIcon =
                  removeNoteLoading === true ? (
                    removeNoteLoadingSpinner
                  ) : (
                    <i
                      style={{ cursor: 'pointer' }}
                      className="tim-icons icon-simple-remove text-danger"
                      onClick={() => removeNote(index)}
                    />
                  );
                return (
                  <Row>
                    <Col>
                      <p>{note}</p>
                    </Col>
                    <Col md="1" className="remove-icon-padding">
                      {noteIcon}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col md="11" className="input-padding-fix">
              <Input
                className="modal-input"
                value={
                  draftNote !== undefined && draftNote.length > 0
                    ? draftNote
                    : ''
                }
                placeholder={`New Note`}
                onChange={(e) => setDraftNote(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && draftNote && draftNote.length > 0) {
                    e.preventDefault();
                    sendNote();
                  }
                }}
              />
            </Col>
            <Col md="1" className="send-button-div">
              {updateNotesButton()}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <div>{errorMessage}</div>
            </Col>
          </Row>
        </ModalBody>
        <div className="modal-footer">
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={toggleEditBox}
          >
            Close
          </Button>{' '}
          {updateAllbutton()}
        </div>
      </Modal>
    </>
  );
};

export default SyndAccountCard;
