import { Routes, Route, Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';

Amplify.configure({
  Auth: {
    userPoolId: 'eu-west-1_r9RI4teYl', // Amazon Cognito Identity Pool ID
    userPoolWebClientId: '3s8c7q4bgnoaou4qonsu3gi6tq',
    region: 'eu-west-1', // Amazon Cognito Region
  },
});

import AdminLayout from './layouts/admin';

// Import site page components
import { DashBoard } from './pages/DashBoard';
import { ValueBets } from './pages/ValueBets';
// import { PaginationTable } from 'components/ValueBetTable/PaginationTable';
import Syndicates from './pages/Syndicates';
import CreateAccount from './pages/CreateAccount';
import Reporting from './pages/Reporting.jsx';
import { History } from './pages/History';
import Analytics from './pages/Analytics';
import AccountsToOpen from 'pages/AccountsToOpen';
import FinalAccounts from 'pages/FinalAccounts';
import Turnover from 'pages/Turnover';

function App() {
  const [userGroup, setUserGroup] = useState();

  useEffect(() => {
    async function fetchData() {
      const user = await Auth.currentAuthenticatedUser();
      const userGroup =
        user.signInUserSession.accessToken.payload['cognito:groups'];

      setUserGroup(userGroup[0]);
    }
    fetchData();
  }, []);

  return (
    <>
      <Routes>
        {(userGroup === 'admin' || userGroup === 'dashboard') && (
          <Route path="/admin" element={<AdminLayout />}>
            <Route path="dashboard" element={<DashBoard />} />
            <Route path="valuebets" element={<ValueBets />} />
            {userGroup === 'admin' && (
              <>
                <Route path="syndicates" element={<Syndicates />} />
                <Route path="createaccount" element={<CreateAccount />} />
                <Route path="reporting" element={<Reporting />} />
                <Route path="history" element={<History />} />
                <Route path="analytics" element={<Analytics />} />
                <Route path="accountsToOpen" element={<AccountsToOpen />} />
                <Route path="finalAccounts" element={<FinalAccounts />} />
                <Route path="turnover" element={<Turnover />} />
              </>
            )}
          </Route>
        )}
        {(userGroup === 'admin' || userGroup === 'dashboard') && (
          <Route
            path="/"
            element={<Navigate to="/admin/dashboard" replace />}
          />
        )}
        {userGroup === 'external' && (
          <Route path="/*" element={<Navigate to="/valuebets" replace />} />
        )}
        {userGroup === 'external' && (
          <Route path="valuebets" element={<ValueBets />} />
        )}
      </Routes>
    </>
  );
}

export default withAuthenticator(App, { hideSignUp: true });
